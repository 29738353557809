import { Alert } from "@suid/material";
import { t } from "i18next";
import { Component, For, createMemo, Show } from "solid-js";
import { config } from "../config";
import { ErrorSeverity, StageError } from "../domain";
/**
 * Error/warning component.
 *
 * @param props The errors that we want to display.
 * @returns An alert component displaying the errors.
 */
export const DebugErrors: Component<{ errors?: StageError[] }> = (props) => {
    const warnings = createMemo(() =>
        props.errors?.filter((x) => x.severity !== ErrorSeverity.WARNING && x.severity !== ErrorSeverity.ERROR) ?? [],
    );

    const errors = createMemo(() =>
        props.errors?.filter((x) => x.severity === ErrorSeverity.FATAL || x.severity === ErrorSeverity.ERROR) ?? [],
    );

    // Warnings only visible in debug or verbose mode.
    const showVerbose = createMemo(() => {
        const uri = new URL(window.location.href);
        const verbose = uri.searchParams.has("verbose");
        return !!warnings().length && (verbose || config.debug.verbose);
    });

    return (
        <>
            <Show when={!!errors().length}>
                <Alert severity="error">
                    {t("error.title.there_are_errors")}
                    <For each={errors()}>
                        {(error) =>
                            <li>
                                {error.message}
                            </li>
                        }
                    </For>
                </Alert>
            </Show>
            {showVerbose()
                ? <Alert severity="warning">
                    {t("error.title.there_are_errors")}
                    <For each={warnings()}>
                        {(warning) =>
                            <li>
                                {warning.message}
                            </li>
                        }
                    </For>
                </Alert>
                : null
            }
        </>
    );
};
