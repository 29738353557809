import { useTheme, Checkbox } from "@suid/material";
import { Component, createSignal } from "solid-js";
import { Button } from "../components/Button";
import { LoginDialog } from "../components/LoginDialog";

export const Test: Component<{ bool?: boolean }> = () => {
    const theme = useTheme();

    const [fullWidth, setFullWidth] = createSignal(false);
    const [fullHeight, setFullHeight] = createSignal(false);
    const [minContentWidth, setMinContentWidth] = createSignal(false);
    const [minContentHeight, setMinContentHeight] = createSignal(false);
    const [fullScreen, setFullScreen] = createSignal(false);
    const [horizontal, setHorizontal] = createSignal(false);

    return (
        <div>
            <LoginDialog
                id="AccountComplete"
                title={"TITLE"}
                description="description"
                link="link"
                fullWidth={fullWidth()}
                minContentWidth={minContentWidth()}
                fullHeight={fullHeight()}
                minContentHeight={minContentHeight()}
                fullscreen={fullScreen()}
                orientation={horizontal() ? "horizontal" : "vertical"}
                primaryButton={
                    <Button
                        data-testid="SubmitButton"
                        sx={theme.mixins.button}
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={true}
                        onClick={() => {}}
                    >
                        SUBMIT
                    </Button>
                }
                errors={[]}
            >
                <div>CONTENT</div>
                <span>long text</span>
                <span>lorem ipsum sid dolor amet of speciaal met frikandel</span>
                <Checkbox checked={fullWidth()} onChange={() => setFullWidth((o) => !o)}>FullWidth</Checkbox>
                <Checkbox checked={fullHeight()} onChange={() => setFullHeight((o) => !o)}>FullHeight</Checkbox>
                <Checkbox checked={minContentWidth()} onChange={() => setMinContentWidth((o) => !o)}>
                    MinContentWidth
                </Checkbox>
                <Checkbox checked={minContentHeight()} onChange={() => setMinContentHeight((o) => !o)}>
                    MinContentHeight
                </Checkbox>
                <Checkbox checked={fullScreen()} onChange={() => setFullScreen((o) => !o)}>FullScreen</Checkbox>
                <Checkbox checked={horizontal()} onChange={() => setHorizontal((o) => !o)}>Horizontal</Checkbox>
            </LoginDialog>
        </div>
    );
};
