/* eslint-disable @typescript-eslint/no-non-null-assertion --
 * Config variables should always be defined by either environment variables or the root node's dataset properties.
 */

import { type Debug, type Features, type Config } from "./types";
import { splitConfigFlags } from "../utilities/SplitConfigFlags";

const dataset = document.getElementById("sso-root")?.dataset ?? {};
const debug = splitConfigFlags<Debug>("debug");
const features = splitConfigFlags<Features>("features");

if (debug.verbose) {
    console.info("Debug variables:", debug);
    console.info("Feature flags:", features);
}
// Get the dataset property if it's not a comment (variable inject macro)
const qrInputUri = dataset.qrUrl?.match(/^<!--(?:(?!-->).)+-->$/) ? import.meta.env.VITE_QR_URL : dataset.qrUrl;

export const config: Config = {
    baseApi: import.meta.env.VITE_BASE_API ?? dataset.baseApi!,

    debug,
    features,
    qrURL: qrInputUri === "" ? undefined : qrInputUri,
    version: {
        number: import.meta.env.VITE_VERSION ?? dataset.version!,
        hash: import.meta.env.VITE_VERSION_HASH,
        env: import.meta.env.VITE_ENV ?? dataset.env!,
        productName: "Prowise SSO",
    },

    locizeProjectId: import.meta.env.VITE_LOCIZE_PROJECT_ID ?? dataset.locizeProjectId!,
    locizeProjectVersion: import.meta.env.VITE_LOCIZE_PROJECT_VERSION ?? dataset.locizeProjectVersion!,
    app: {
        authority: import.meta.env.VITE_APP_AUTHORITY ?? dataset.appAuthority!,
        clientId: import.meta.env.VITE_APP_CLIENT_ID ?? dataset.appClientId!,
        url: import.meta.env.VITE_APP_URL ?? dataset.appUrl,
    },
};
