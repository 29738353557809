import { Link, Popover, Typography, useTheme } from "@suid/material";
import { type LinkTypeMap } from "@suid/material/Link";
import { Component, createMemo, createSignal, For, splitProps } from "solid-js";

const mdLink = /\[([^[\]]*)\]\(([^()\s]*)(?:\s*['"]([^()'"]*)['"])?\)/g;

export const createMarkdownLinkParts = (text: string): string[] => text.split(mdLink);
export enum LinkPart {
    TEXT,
    LINK_TEXT,
    LINK,
    TOOLTIP,
    COUNT,
}

/**
 * Convert Markdown text into text with Link component.
 *
 * @param props The component props.
 * @param props.text The Markdown decorated text (links only)
 * @returns Text with Markdown link replaced with working Link component.
 */
export const MarkdownLink: Component<LinkTypeMap["props"] & { text: string; id?: string }> = (props) => {
    if (!props.text) {
        console.warn("Empty markdown text should not be empty");

        // eslint-disable-next-line solid/components-return-once -- Special case: should not occur
        return undefined;
    }

    let el!: HTMLElement;
    const [local, other] = splitProps(props, ["text", "id"]);

    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);

    const handlePopoverOpen = (event: { currentTarget: Element }): void => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (event: Event): void => {
        if (document.activeElement !== event.currentTarget) setAnchorEl(null);
    };

    const theme = useTheme();
    // Components will be split in 3 repeatedly: a regular string, the link text and the link.
    const components = createMemo(() => createMarkdownLinkParts(local.text ?? ""));

    return (
        <Typography class="MarkdownLink" id={local.id} ref={el} {...other}>
            <For each={components().map((c, n) => {
            // Regular string
                if (!(n % LinkPart.COUNT)) return c;

                // Link text (create Link element)
                if ((n % LinkPart.COUNT) === LinkPart.LINK_TEXT) {
                    // @ts-expect-error - We fill in the type directly after.
                    const ref: Record<"href" | "tabindex", string> = {};
                    if (components()[n + 1] === ".") {
                        ref.tabindex = "1";
                    } else {
                        ref.href = components()[n + 1];
                    }
                    return (<>
                        <Link
                            {...other}
                            sx={{ ...other.sx, p: 0, m: 0, ...theme.mixins.link }}
                            {...ref}
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                            onFocus={handlePopoverOpen}
                            onBlur={handlePopoverClose}
                        >
                            {c}
                        </Link>
                        {components()[n + 2] &&
                            <Popover
                                id="mouse-over-popover"
                                sx={{ ...theme.mixins.tooltip, pointerEvents: "none" }}
                                open={!!anchorEl()}
                                anchorEl={anchorEl()}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                disableRestoreFocus
                                container={el}
                            >
                                <Typography sx={{ p: 1 }}>{components()[n + 2]}</Typography>
                            </Popover>}
                    </>);
                }
                return null;
            })}>
                {(elem) => elem}
            </For>
        </Typography>
    );
};
